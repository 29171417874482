import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Posting - Analytics",
  "description": "Kennzahlen",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-08-13T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Warum sind Kennzahlen wichtig?`}</h2>
    <p>{`Wie gut eine Jobbörse funktioniert, ist je Position und Unternehmen sehr unterschiedlich. Die Analyse der Kennzahlen hilft Ihnen, fundierte Entscheidungen bei der Auswahl von Jobbörsen zu treffen und dadurch mit gleichem Budget mehr qualifizierte Bewerber zu erreichen.`}</p>
    <h2>{`Folgende Kennzahlen werden derzeit ausgegeben:`}</h2>
    <ul>
      <li parentName="ul">{`Klicks pro Jobbörse`}</li>
      <li parentName="ul">{`Klicks pro Stellenanzeige`}</li>
      <li parentName="ul">{`Anzahl der Anzeigen pro Jobbörse`}</li>
      <li parentName="ul">{`Gesamtklicks pro Jobbörse`}</li>
    </ul>
    <p>{`Hinweis: Eine 100-prozentige Messung kann nicht gewährleistet werden. Die Daten bieten jedoch einen guten Anhaltspunkt dafür, welche Jobbörsen funktionieren. Wir arbeiten daran, in Zukunft weitere Kennzahlen zur Verfügung stellen zu können.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      